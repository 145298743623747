import React, { Component } from 'react';
import logo from '../assets/logo-ps.png';
import '../css/genstyle.css';
import NumberFormats from './number-format';


class ThankyouPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            package_type_name: localStorage.getItem('packagetype'),
            domain_name: localStorage.getItem('domainname'),
            grand_total: localStorage.getItem('gtotal'),
            currency: localStorage.getItem('currency')
        }
    }
    
    loadPage(type) {
        var result = '';
        if(type === 'enterprise' || type === 'contactseller') {  
            result += '<div id="confmessage-enterprise" className="container conf-wrapper3 divHide">';
            result += '<div className="row mt-20 mb-30"><div className="col">';
            result += '<div style="margin-top:20px"><strong>Thank you for your order.</strong></div>';
            result += '<div style="margin-top:20px">You&acute;ve already order your renewal for Google Workspace Enterprise. Your order will be processed in short time.</div>';
            result += '<div style="margin-top:10px">Please check your inbox for an email from<strong> PointStar</strong> (license@point-star.com). There are key pieces of information that you need to provide us for us to proceed with the Google Workspace setup.</div>';
            result += '<div style="margin-top:10px">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete.</div>';
            result += '<div style="margin-top:10px">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
            result += '</div></div>';
            result += '</div>';
        } else if(type === 'cash') {
            
            result += '<div id="confmessage-cheque" className="container conf-wrapper2 divHide">';
            result += '<div className="row"><div className="col padRL-15 mt-20"><h4>Send us a cheque</h4></div></div>';
            result += '<div className="row mt-20 mb-30"><div className="col">';
            result += '<div style="margin-top:20px"><strong>Thank you for your order, to pay by cheque:</strong></div>';
            result += '<div style="margin-top:10px">Write a cheque payable to &quot;<strong> PointStar Pte Ltd</strong> &quot; for the amount of <strong> '+ this.state.currency +' ' +NumberFormats(this.state.grand_total)+'</strong>.</div>';
            result += '<div style="margin-top:10px">Write your domain and no. of Google Workspace licences (if applicable) at the back of the cheque for faster and more accurate processing.</div>';
            result += '<div style="margin-top:10px">No receipt will be provided for cheque payment. We will contact you by email and/or phone to confirm we&acute;ve received it and beginning work on setting up Google Workspace for your domain. We will only proceed with setup once we&acute;ve received it.</div>';
            result += '<div style="margin-top:10px">Mail / courier your cheque to:<br /><br /><strong>PointStar Pte Ltd <br />51 Ayer Rajah Crescent #06-10 <br />Singapore 139948</strong> <br /></div>';
            result += '<div style="margin-top:10px">Please check your inbox for an email from <strong>PointStar</strong> (license@point-star.com) with the subject: <strong>&quot;Your Order: '+this.state.domain_name+' on '+this.state.package_type_name+'&quot;</strong>. It contains crucial instructions on how to proceed. There are key pieces of information that you need to provide us for us to proceed with the Google Workspace setup.</div>';
            result += '<div style="margin-top:10px">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete, and that if your cheque payment do not reach us within 72 hours, we will treat your order as <u>cancelled</u>.</div>';
            result += '<div style="margin-top:10px">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
            result += '</div></div>';
            result += '</div>';
            
        } else if(type === 'paypal') {
            result += '<div id="confmessage-paypal" className="container conf-wrapper1 divHide">';
            result += '<div className="row"><div className="col padRL-15 mt-20"><h4>You&acute;ve paid via PayPal</h4></div></div>';
            result += '<div className="row mt-20 mb-30"><div className="col">';
            result += '<div style="margin-top:20px"><strong>Thank you for your order and payment.</strong></div>';
            result += '<div style="margin-top:20px">You&acute;ve sent your payment via PayPal.</div>';
            result += '<div style="margin-top:10px">Please check your inbox for an email from<strong> PointStar</strong> (license@point-star.com). It contains crucial instructions on how to proceed. There are key pieces of information that you need to provide us for us to proceed with the Google Workspace setup.</div>';
            result += '<div style="margin-top:10px">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete.</div>';
            result += '<div style="margin-top:10px">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
            result += '</div></div>';
            result += '</div>';
        } else if(type === 'banktransfer') {
            
            result += '<div id="confmessage-bt" className="container conf-wrapper2 divHide">';
            result += '<div className="row"><div className="col padRL-15 mt-20"><h4>Transfer to Bank Account</h4></div></div>';
            result += '<div className="row mt-20 mb-30 border"><div className="col">';
            result += '<div style="margin-top:20px"><strong>Thank you for your order</strong></div>';
            result += '<div style="margin-top:20px">Please continue your payment by transfer to &quot;<strong> PointStar Pte Ltd</strong> &quot; Bank Account for the amount of <strong>'+this.state.currency+' '+NumberFormats(this.state.grand_total)+' </strong></div>';
            result += '<div style="margin-top:20px">Transfer your payment to:<br /><br />';
            if(this.state.currency === "SGD") { 
                result += '<strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 147-731533-001</strong>';
            } else if(this.state.currency === "USD") {
                result += '<strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 260-659453-178</strong>';
            } else {
                result += '<strong>To Be Confirmed</strong>';
            }
            result += '</div>';
            result += '<div style="margin-top:20px">Input your domain and no. of licences (if applicable) in the message field when you do the transfer for faster and more accurate processing.</div>';
            result += '<div style="margin-top:10px">No receipt will be provided for this payment. We will contact you by email and/or phone to confirm we&acute;ve received it and beginning work on setting up your request for your domain. We will only proceed with setup once we&acute;ve received it.</div>';
            result += '<div style="margin-top:10px">Please check your inbox for an email from <strong>PointStar</strong> (license@point-star.com) with the subject: <strong>&quot;Your Order: '+this.state.domain_name +' on '+this.state.package_type_name+'&quot;</strong>. It contains crucial instructions on how to proceed. There are key pieces of information that you need to provide us for us to proceed with the setup.</div>';
            result += '<div style="margin-top:10px">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete, and that if your payment do not reach us within 72 hours, we will treat your order as <u>cancelled</u>.</div>';
            result += '<div style="margin-top:10px">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
            result += '</div></div>';
            result += '</div>';
        } else if(type === 'error') {
            result += '<div id="confmessage-error" className="container conf-wrapper-error">';
            result += '<div className="row"><div className="col padRL-15 mt-20"><h4>The Order is not success</h4></div></div>';
                
            result += '<div className="row mt-20 mb-30"><div className="col">';
            result += 'Your order for '+this.state.domain_name +' is not success. <br/>';
            result += 'Please re-Order again in <a href="https://addlicence.point-star.com">here</a><br/>';
            result += 'Sorry for this inconvenience.<br/><br/>';
            result += 'If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).';
            result += '</div></div>';
            result += '</div>';
        } else  {
            result += '<div id="confmessage-error" className="container conf-wrapper-error">';
            result += '<div className="row"><div className="col padRL-15 mt-20"><h4>The Order is not available</h4></div></div>';
                
            result += '<div className="row mt-20 mb-30"><div className="col">';
            result += 'Please re-Order again in <a href="https://addlicence.point-star.com">here</a><br/>';
            result += 'Sorry for this inconvenience.<br/><br/>';
            result += 'If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).';
            result += '</div></div>';
            result += '</div>';
        }

        document.getElementById("third-step").innerHTML = result;
    }
    componentDidMount() {
        this.setState({
            package_type_name: localStorage.getItem('packagetype'),
            domain_name: localStorage.getItem('domainname'),
            grand_total: localStorage.getItem('gtotal'),
            currency: localStorage.getItem('currency')
        })
        this.loadPage(localStorage.getItem('type'));
    }
    
    render() {
       
        return (
            <div className="main-container flex-container">
                <div className="w100p">
                    <div className="divRight"><div className="mt-20 mr-20"><img src={logo} alt="" width="160"/></div></div>
                    <div className="main-content">
                        <div className="mb-30"><h1>Additional Google Workspace Licence Form</h1></div>
                        {/* ===================== */ }
                        <div id="third-step"></div>
                        {/* ===================== */ }
                    </div>
                </div>
            </div>
        )
    }
}
export default ThankyouPage;