import React, { Component } from 'react';
import logo from '../assets/logo-ps.png';
import '../css/genstyle.css';


class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValid: false,
        }
    }
    
    
    componentDidMount() {
        localStorage.clear();
    }
    
    render() { 
        return (
            <div className="main-container flex-container">
                <div className="w100p" id="na-page">
                    <div className="divCenter"><div className="mt-50"><img src={logo} alt="" width="160"/></div></div>
                    
                    <div className="mt-20 mb-20">
                        <div style={{height:"20px"}}></div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="divCard divCenter">
                                <div className="mb-20 padRL-15" style={{fontSize:"25pt", color:"#03468a"}}>PAGE NOT AVAILABLE</div>
                                <div className="padRL-15" style={{fontSize:"13pt", color:"#595959"}}>Sorry, this page is not available.</div>
                                <div className="mb-20 padRL-15" style={{fontSize:"13pt", color:"#595959"}}>Please contact your account representative for more information.</div>
                            </div>
                        </div>
                        <div style={{height:"200px"}}></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ErrorPage;