
module.exports.message = function(value, mtype, vault, tax, typeprice) {
    var callseller = "contactseller";
    var subject_email, body_email;
    if(value === 'admin') {
        if (mtype === callseller) { 
            subject_email = '[New Order: Licences Only] [[domainname]] | [[licenseval]] GAL | [[packageTypeName]]';
        } else {
            subject_email = '[New Order: Licences Only] [[domainname]] | [[licenseval]] GAL | [[currencyType]] [[grandtotal]]';
        }
        body_email = '';
        body_email += '<table style="width=100%; border: .5px solid rgb(45,45,45); display:table;">';
        body_email += '<tr style="width:100%; height:100px; background:rgb(45,45,45); display:table;"><th style="text-align: left; padding: 0 15px; color: white; font-weight: lighter;"><img src="cid:logo" width="100" alt=""><br><span style="font-style: italic;font-size: 10px;">"Bringing Cloud to the World One Business at a Time."</span></th></tr>';
        body_email += '<tr style="display:table; margin-top:20px;"><td>New PointStar <b>[[packageTypeName]]</b> Licence order :</td></tr>';
        body_email += '<tr><td style="text-align:center; font-weight:bold; font-size:20px; border-bottom:1px solid; display:table; margin-top:20px;">Order Details</td></tr>';
        
        body_email += '<tr><td style="display:table;" width="100%"><table width="100%">';
  
        body_email += '<tr><td align="right" valign="top" width="39%">Requester&acute;s Name</td><td align="center" valign="top" width="2%">:</td><td valign="top" width="59%">[[contactname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester&acute;s Email</td><td align="center" valign="top">:</td><td valign="top">[[contactemail]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester&acute;s Phone</td><td align="center" valign="top">:</td><td valign="top">[[contactphone]]</td></tr>';
  
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top">Selected Licence Type</td><td align="center" valign="top">:</td><td valign="top">[[packageTypeName]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company&acute;s Domain Name</td><td align="center" valign="top">:</td><td valign="top">[[domainname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company&acute;s Name</td><td align="center" valign="top">:</td><td valign="top">[[companyname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Country of Business</td><td align="center" valign="top">:</td><td valign="top">[[country]]</td></tr>';
        if (mtype === callseller) {
            body_email += '<tr><td align="right" valign="top">No. of Licence(s) Requested </td><td align="center" valign="top">:</td><td valign="top">[[licenseval]]</td></tr>';
        }
        body_email += '<tr><td align="right" valign="top">Month of Renewal</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]</td></tr>';
        if (typeprice === 'existCustomer') {
            body_email += '<tr><td align="right" valign="top">Last Renewal Date</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]&nbsp;[[renYear]]</td></tr>';
        } else {
            body_email += '<tr><td align="right" valign="top">Purchase Month</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]&nbsp;[[renYear]]</td></tr>';
        }
        
      if (mtype === callseller) { } 
      else {
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top" style="width:35%;">Selected Payment Method</td><td align="center" valign="top">:</td><td valign="top">[[methodmessage]]</td></tr>';
        
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td height="10" colspan="3"><center><table>';
        body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="50%">Description</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Quantity</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price ([[currencyType]])</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="20%">Frequency</td></tr>';
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licensevalRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">Yearly</td></tr>';
        if (vault > 0) { 
            body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences </td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vault]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[vaulttotal_output]]</td><td style="border:solid 1px #666; padding:5px; text-align:center;">Yearly</td></tr>';
        }			
        if (tax > 0) {			
            body_email += '<tr><td style="border:solid 1px #666; padding:5px;">+8% GST (Goods & Services Tax)</td><td style="border:solid 1px #666; padding:5px;"></td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[taxRp_output]]</td><td style="border:solid 1px #666; padding:5px;"></td></tr>'
        }
        body_email += '<tr><td style="font-weight:bold; padding:5px; text-align:center; font-size:130%;">Total</td><td style="padding:5px; text-align:center;"></td><td style="padding:5px; font-weight:bold; font-size:130%; text-align:center;">[[grandtotal]]</td><td></td></tr>';
        body_email += '</table></center></td></tr>';
      }
        body_email += '</table></td></tr>';	
        body_email += '</table>';
        
    } else {
        subject_email = "Your Order: [[domainname]] on [[packageTypeName]]";
        
        body_email = '';
        body_email += '<table style="width=600px; border: .5px solid rgb(45,45,45); display:table;">';
        body_email += '<tr style="width:100%; height:100px; background:rgb(45,45,45); display:table;"><th style="text-align: left; padding: 0 15px; color: white; font-weight: lighter;"><img src="cid:logo" width="100" alt="asd"><br><span style="font-style: italic;font-size: 10px;">"Bringing Cloud to the World One Business at a Time."</span></th></tr>';
        body_email += '<tr style="display:table; margin-top:20px;"><td>Dear <span style="font-weight:bold;">[[contactname]]</span>,</td></tr>';
        body_email += '<tr style="display:table; margin-top:10px;"><td>Congratulations on expanding your company bigger. Thank you for purchasing additional <span style="font-weight:bold;">[[packageTypeName]]</span> Licence order on <a href="https://www.point-star.com/">point-star.com</a>. Below are your order details. Please save this email for your own record.</td></tr>';
        body_email += '<tr><td style="text-align:center; font-weight:bold; font-size:20px; border-bottom:1px solid; display:table; margin-top:20px;">Order Details</td></tr>';
        
        body_email += '<tr><td style="display:table;" width="100%"><table width="100%">';
  
        body_email += '<tr><td align="right" valign="top" width="39%">Requester&acute;s Name</td><td align="center" valign="top" width="2%">:</td><td valign="top" width="59%">[[contactname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester&acute;s Email</td><td align="center" valign="top">:</td><td valign="top">[[contactemail]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester&acute;s Phone</td><td align="center" valign="top">:</td><td valign="top">[[contactphone]]</td></tr>';
  
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top">Selected Licence Type</td><td align="center" valign="top">:</td><td valign="top">[[packageTypeName]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company&acute;s Domain Name</td><td align="center" valign="top">:</td><td valign="top">[[domainname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company&acute;s Name</td><td align="center" valign="top">:</td><td valign="top">[[companyname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Country of Business</td><td align="center" valign="top">:</td><td valign="top">[[country]]</td></tr>';
        
      if (mtype === callseller) { } 
      else {
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top" style="width:35%;">Selected Payment Method</td><td align="center" valign="top">:</td><td valign="top">[[methodmessage]]</td></tr>';
        
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td height="10" colspan="3"><center><table>';
        body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="50%">Description</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Quantity</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price ([[currencyType]])</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="20%">Frequency</td></tr>';
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licensevalRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">Yearly</td></tr>';
        if (vault > 0) { 
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences</td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vault]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[vaulttotal_output]]</td><td style="border:solid 1px #666; padding:5px; text-align:center;">Yearly</td></tr>';
        }			
        if (tax > 0) {			
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">+8% GST (Goods & Services Tax)</td><td style="border:solid 1px #666; padding:5px;"></td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[taxRp_output]]</td><td style="border:solid 1px #666; padding:5px;"></td></tr>'
        }
        body_email += '<tr><td style="font-weight:bold; padding:5px; text-align:center; font-size:130%;">Total</td><td style="padding:5px; text-align:center;"></td><td style="padding:5px; font-weight:bold; font-size:130%; text-align:center;">[[grandtotal]]</td><td></td></tr>';
        body_email += '</table></center></td></tr>';
      }
        body_email += '</table></td></tr>';	
  
        body_email += '<tr style="display:table; margin-top:20px;"><td>';
        if (mtype === callseller) {
        body_email += 'Your order will be processed in short time.<br><br>';
        }
        body_email += 'Please note that by proceeding with this order, you had already agreed to PointStar&acute;s <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank">terms and conditions</a> as Google&acute;s Reseller.<br/><br/>Your order will be processing during office operation hours from 9 am to 6 pm UTC +8 (Mondays to Fridays, excl. public holidays).  If you are ordering from Indonesia, processing hours would be 8 am to 5 pm UTC +8 (Mondays to Fridays, excl. public holidays).<br/><br/>If you have any other queries or concerns, you may call us at <a href="tel:+6567730987">+65 6773 0987</a> on Singapore operation hours or <a href="tel:+622125558789">+62 21 2555 8789</a> on Indonesia operation hours to speak to one of our PointStar assistants who are always happy to help.</td></tr>';
        //body_email += 'Please note that by proceeding with this order, you had already agreed to PointStar&acute;s <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank">terms and conditions</a>.<br/><br/>If you have any other queries or concerns, you may call us at <a href="tel:+6567730987">+65 6773 0987</a> (Mondays to Fridays, 9:00 am to 6:00 pm Singapore time) to speak to one of our PointStar assistants who are always happy to help.</td></tr>';
        body_email += '</table>';
  
    }
    return [subject_email,body_email];
}
