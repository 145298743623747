
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './app/home';
import ThankyouPage from './app/system-msg';
import ErrorPage from './app/page404';

var mainPage = <HomePage/>;
var thankyouPage = <ThankyouPage/>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={mainPage} />
      <Route path="/aA56ROwq924msyl" element={mainPage} />
      <Route path="/licencesuccess" element={thankyouPage} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);
reportWebVitals();
