const MonthAsNumber = (value) => {
    var result=0;
    if(value === "January") { result = 1; }
    else if(value === "February") { result = 2; }
    else if(value === "March") { result = 3; }
    else if(value === "April") { result = 4; }
    else if(value === "May") { result = 5; }
    else if(value === "June") { result = 6; }
    else if(value === "July") { result = 7; }
    else if(value === "August") { result = 8; }
    else if(value === "September") { result = 9; }
    else if(value === "October") { result = 10; }
    else if(value === "November") { result = 11; }
    else if(value === "December") { result = 12; }
    return (result);
};
export default MonthAsNumber;