import React, { Component } from 'react';
import logo from '../assets/logo-ps.png';
import picpaypal from '../assets/pic_paypal.gif';
import picbt from '../assets/pic_bt.png';
import '../css/genstyle.css';
import { FormErrors } from './error-form';
import MonthAsNumber from './month-number';
import RoundDecimal from './round-decimal';
import NumberFormats from './number-format';
import ReplaceAllContent from './replace-all';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const packageNameArr = ["none","Google Workspace Business Starter","Google Workspace Business Standard","Google Workspace Business Plus","Google Workspace Enterprise Standard","Google Workspace Enterprise Plus"];
const pricingUSDArr = [1000000,72,144,216,240,360];
const pricingSGDArr = [1000000,96,192,288,320,480];
const callseller = "contactseller";
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            package_type: '',
            domain_name: '',
            company_name: '',
            requester_name: '',
            requester_email: '',
            requester_phone: '',
            country: '',
            licence_seat: 0,
            enable_vault: 'No',
            request_vault: 0,
            month_renewal: '',
            year_renewal: '',
            currency_type: '',

            package_type_name: '', 
            licence_seat_rp: 0,
            request_vault_rp: 0,
            tax_value_rp: 0,
            grand_total: 0,
            method_payment: '',
            is_tnc: false,
            formErrors: {package_type: '',domain_name: '',company_name: '',requester_name: '',requester_email: '',requester_phone: '',country: '',licence_seat: '',enable_vault: '',request_vault: '',month_renewal: '', method_payment: '', is_tnc:'', currency_type: ''},
            packageTypeValid: false,
            domainNameValid: false,
            companyNameValid: false,
            requesterNameValid: false,
            requesterEmailValid: false,
            requesterPhoneValid: false,
            countryValid: false,
            licenceSeatValid: false,
            enableVaultValid: false,
            requestVaultValid: false,
            monthRenewalValid: false,
            methodPaymentValid: false,
            isTncValid: false,
            currencyTypeValid: false,
            formValid: false,
        }
        this.handleSubmit1 = this.handleSubmit1.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if(name === "domain_name") {
            this.setState( prevState => {
                return {  
                    ...prevState.domain_name, domain_name: value
                }
            })
        } else {
            this.setState({[name]: value},
                () => { this.validateField(name, value)});
        }
    }
    handlePackageType = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        var mthd_paym = "";
        if(value === "basic") {
            document.getElementById("vault-area").style.display = "block";
        } else {
            document.getElementById("vault-area").style.display = "none";
            if(value === "4") { mthd_paym = callseller; }
            else if(value === "5") { mthd_paym = callseller; }
        }
        
        this.setState( prevState => {
            return {  
                ...prevState.package_type, package_type: value,
                ...prevState.method_payment, method_payment: mthd_paym
            }
        }, () => { this.validateField(name, value) })
    }
    handleCurrencyType = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState( prevState => {
            return {  
                ...prevState.currency_type, currency_type: value
            }
        }, () => { this.validateField(name, value) })
    }
    handleUserSelect = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if(name === "enable_vault") {
            if(value === "Yes") { document.getElementById("vault-value-area").style.display = "block"; }
            else { document.getElementById("vault-value-area").style.display = "none"; }
            this.setState( prevState => {
                return {  
                    ...prevState.enable_vault, enable_vault: value
                }
            }, () => { this.validateField(name, value) })
        } else if(name === "country") {
            this.setState( prevState => {
                return {  
                    ...prevState.country, country: value
                }
            }, () => { this.validateField(name, value) })
        } else if(name === "month_renewal") {
            this.setState( prevState => {
                return {  
                    ...prevState.month_renewal, month_renewal: value
                }
            }, () => { this.validateField(name, value) })
        } else if(name === "method_payment") {
            this.setState( prevState => {
                return {  
                    ...prevState.method_payment, method_payment: value
                }
            }, () => { this.validateField(name, value) })
        } else if(name === "is_tnc") {
            this.setState( prevState => {
                return {  
                    ...prevState.is_tnc, is_tnc: e.target.checked
                }
            }, () => { this.validateField(name, value) })
        }
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let packageTypeValid = this.state.packageTypeValid;
        let domainNameValid = this.state.domainNameValid;
        let companyNameValid = this.state.companyNameValid;
        let requesterNameValid = this.state.requesterNameValid;
        let requesterEmailValid = this.state.requesterEmailValid;
        let requesterPhoneValid = this.state.requesterPhoneValid;
        let countryValid = this.state.countryValid;
        let licenceSeatValid = this.state.licenceSeatValid;
        let enableVaultValid = this.state.enableVaultValid;
        let requestVaultValid = this.state.requestVaultValid;
        let monthRenewalValid = this.state.monthRenewalValid;
        let methodPaymentValid = this.state.methodPaymentValid;
        let isTncValid = this.state.isTncValid;
        let currencyTypeValid = this.state.currencyTypeValid;

        switch(fieldName) {
            case 'package_type': 
              packageTypeValid = value.trim().length > 0;
              fieldValidationErrors.package_type = packageTypeValid ? '' : 'field cannot be empty';
              break;
            case 'domain_name': 
              if(value.trim().length > 0) {
                const domainRegex = /\S+\.\S+/;
                domainNameValid = domainRegex.test(value) !== false
                fieldValidationErrors.domain_name = domainNameValid ? '' : 'invalid format';
              } else {
                domainNameValid = value.trim().length > 0;
                fieldValidationErrors.domain_name = domainNameValid ? '' : 'field cannot be empty';
              }
                break;
            case 'company_name': 
              companyNameValid = value.trim().length > 0;
              fieldValidationErrors.company_name = companyNameValid ? '' : 'field cannot be empty';
              break;
            case 'requester_name': 
              requesterNameValid = value.trim().length > 0;
              fieldValidationErrors.requester_name = requesterNameValid ? '' : 'field cannot be empty';
              break;
            case 'requester_email': 
              if(value.trim().length > 0) {
                const emailRegex = /\S+@\S+\.\S+/;
                requesterEmailValid = emailRegex.test(value) !== false
                fieldValidationErrors.requester_email = requesterEmailValid ? '' : 'invalid format';
              } else {
                requesterEmailValid = value.trim().length > 0;
                fieldValidationErrors.requester_email = requesterEmailValid ? '' : 'field cannot be empty';
              }
              break;
            case 'requester_phone': 
              requesterPhoneValid = value.trim().length > 0;
              fieldValidationErrors.requester_phone = requesterPhoneValid ? '' : 'field cannot be empty';
              break;
            case 'country': 
              countryValid = value.trim().length > 0;
              fieldValidationErrors.country = countryValid ? '' : 'field cannot be empty';
              break;
            case 'licence_seat': 
              licenceSeatValid = value > 0;
              fieldValidationErrors.licence_seat = licenceSeatValid ? '' : 'field cannot be empty';
              break;
            case 'enable_vault': 
              enableVaultValid = value.trim().length > 0;
              fieldValidationErrors.enable_vault = enableVaultValid ? '' : 'field cannot be empty';
              break;
            case 'request_vault': 
              if(this.state.enable_vault === "Yes") {
                requestVaultValid = value > 0;
                fieldValidationErrors.request_vault = requestVaultValid ? '' : 'field cannot be empty';
              }
              break;
            case 'month_renewal': 
              monthRenewalValid = value.trim().length > 0;
              fieldValidationErrors.month_renewal = monthRenewalValid ? '' : 'field cannot be empty';
              break;
            case 'currency_type': 
              currencyTypeValid = value.trim().length > 0;
              fieldValidationErrors.currency_type = currencyTypeValid ? '' : 'field cannot be empty';
              break;
            case 'method_payment': 
              methodPaymentValid = value.trim().length > 0;
              fieldValidationErrors.method_payment = methodPaymentValid ? '' : 'field cannot be empty';
              break;
            case 'is_tnc': 
              isTncValid = value !== false;
              fieldValidationErrors.is_tnc = isTncValid ? '' : 'please accept the terms and condition';
              break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        packageTypeValid: packageTypeValid,
                        domainNameValid: domainNameValid,
                        companyNameValid: companyNameValid,
                        requesterNameValid: requesterNameValid,
                        requesterEmailValid: requesterEmailValid,
                        requesterPhoneValid: requesterPhoneValid,
                        countryValid: countryValid,
                        licenceSeatValid: licenceSeatValid,
                        enableVaultValid: enableVaultValid,
                        requestVaultValid: requestVaultValid,
                        monthRenewalValid: monthRenewalValid,
                        methodPaymentValid: methodPaymentValid,
                        isTncValid: isTncValid,
                        currencyTypeValid: currencyTypeValid
                    }, this.validateForm);                            
    }
    validateForm() {
        this.setState({formValid: this.state.packageTypeValid && this.state.domainNameValid && this.state.companyNameValid  && this.state.requesterNameValid  && this.state.requesterEmailValid  && this.state.requesterPhoneValid  && this.state.countryValid  && this.state.licenceSeatValid && this.state.monthRenewalValid && this.state.methodPaymentValid && this.state.isTncValid && this.state.currencyTypeValid});
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit2(e) {
        e.preventDefault();
        
        if((this.state.method_payment !== '' && this.state.is_tnc !== false) || this.state.package_type === "enterprise") {
            document.getElementById('loading-area').style.display = 'block'; 
            var URL_SENDMAIL = 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/api/sendmaildata';
            var from_email = "license@point-star.com";
            var to_admin_email = "accountmanagement@point-star.com";  
            var to_cust_email = this.state.requester_email;
            
            var template = require('./template.js');
            var methodmessage = "";
            if (this.state.method_payment === 'paypal') {
                methodmessage = "PayPal (the above amount has to reach our PayPal account within 24 hours, failing which this order might be delayed or cancelled)";
            } else if (this.state.method_payment === 'banktransfer') {
                methodmessage = "Wire / Bank Transfer";
                if(this.state.currency_type === "SGD") { 
                    methodmessage += '<br/><br/>Transfer your payment to:<br/><strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 147-731533-001</strong>';
                } else if(this.state.currency_type === "USD") {
                    methodmessage += '<br/><br/>Transfer your payment to:<br/><strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 260-659453-178</strong>';
                }
                methodmessage += "<br/><br/>*) the payment has to reach our account within 72 hours, failing which this order might be delayed or cancelled";
            } else { 
                methodmessage = "Cheque (the cheque has to reach our office within 72 hours, failing which this order might be delayed or cancelled)";
            }
            /* ==== SENT EMAIL TO ADMIN ============ */
            var subject_email = "", body_email = "";
            var subject_admin_email, body_admin_email,subject_cust_email, body_cust_email;
            var arrTemplate = [
                template.message("admin", this.state.method_payment, this.state.request_vault, this.state.tax_value_rp, ''),
                template.message("customer", this.state.method_payment, this.state.request_vault, this.state.tax_value_rp, '')
            ];
            for(var s=0; s<arrTemplate.length; s++) {
                subject_email =  arrTemplate[s][0]; 
                body_email = arrTemplate[s][1];
                
                subject_email = ReplaceAllContent('[[domainname]]',this.state.domain_name,subject_email);
                subject_email = ReplaceAllContent('[[licenseval]]',this.state.licence_seat,subject_email);
                subject_email = ReplaceAllContent('[[packageTypeName]]',this.state.package_type_name,subject_email);
                subject_email = ReplaceAllContent('[[grandtotal]]',NumberFormats(this.state.grand_total),subject_email);
                subject_email = ReplaceAllContent('[[currencyType]]',this.state.currency_type,subject_email);
                
                body_email = ReplaceAllContent('[[packageTypeName]]',this.state.package_type_name,body_email);
                body_email = ReplaceAllContent('[[contactname]]',this.state.requester_name,body_email);
                body_email = ReplaceAllContent('[[contactemail]]',this.state.requester_email,body_email);
                body_email = ReplaceAllContent('[[contactphone]]',this.state.requester_phone,body_email);
                body_email = ReplaceAllContent('[[domainname]]',this.state.domain_name,body_email);
                body_email = ReplaceAllContent('[[companyname]]',this.state.company_name,body_email);
                body_email = ReplaceAllContent('[[country]]',this.state.country,body_email);
                body_email = ReplaceAllContent('[[licenseval]]',this.state.licence_seat,body_email);
                body_email = ReplaceAllContent('[[licmonthval]]',this.state.month_renewal,body_email);
                body_email = ReplaceAllContent('[[renYear]]',this.state.year_renewal,body_email);
                body_email = ReplaceAllContent('[[methodmessage]]',methodmessage,body_email);
                body_email = ReplaceAllContent('[[licensevalRp_output]]',NumberFormats(this.state.licence_seat_rp),body_email);
                body_email = ReplaceAllContent('[[vault]]',this.state.request_vault,body_email);
                body_email = ReplaceAllContent('[[vaulttotal_output]]',NumberFormats(this.state.request_vault_rp),body_email);
                body_email = ReplaceAllContent('[[taxRp_output]]',NumberFormats(this.state.tax_value_rp),body_email);
                body_email = ReplaceAllContent('[[grandtotal]]',NumberFormats(this.state.grand_total),body_email);
                body_email = ReplaceAllContent('[[currencyType]]',this.state.currency_type,body_email);
                
                if(s===0) { 
                    subject_admin_email =  subject_email; 
                    body_admin_email = body_email;
                } else { 
                    subject_cust_email =  subject_email; 
                    body_cust_email = body_email;
                } 
            }  
            
            var data_admin = { to: to_admin_email, from: from_email, subject: subject_admin_email, Message: body_admin_email };
            var data_customer = { to: to_cust_email, from: from_email, subject: subject_cust_email, Message: body_cust_email };

            var returnsuccess = "https://addlicence.point-star.com/licencesuccess/";
            var returncancel = "https://addlicence.point-star.com/";					
            if(window.location.hostname === 'localhost') {
                returnsuccess = "http://localhost:4200/licencesuccess/";
                returncancel = "http://localhost:4200/";
            }
            
            try {
              // === Send to Admin ======
              fetch(URL_SENDMAIL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }, body : JSON.stringify(data_admin)
                }).then(res => {
                    if(res.status === 200) { return res.json();  }
                }).then( resJson => {
                    // === Send to Customer ======
                    fetch(URL_SENDMAIL, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        }, body : JSON.stringify(data_customer)
                        }).then(res => {
                            if(res.status === 200) { return res.json();  }
                        }).then( resJson => {
                            document.getElementById('loading-area').style.display = 'none'; 
                            
                            document.getElementById("first-step").style.display = "none";
                            document.getElementById("second-step").style.display = "none";
                            
                            localStorage.setItem('domainname', this.state.domain_name);
                            localStorage.setItem('gtotal', this.state.grand_total);
                            localStorage.setItem('packagetype', this.state.package_type_name);
                            localStorage.setItem('currency', this.state.currency_type);

                            if (this.state.package_type === 'enterprise') {
                                localStorage.setItem('type', this.state.package_type);
                                document.location.href= returnsuccess;
                            } else {
                                localStorage.setItem('type', this.state.method_payment);
                                if (this.state.method_payment === 'paypal') {
                                    var gTotalURL = this.state.grand_total;
                                    var linkURL_Paypal = 'https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=admin@point-star.com&lc=SG&item_name=PointStar-'+this.state.domain_name+'&item_number=cg-'+this.state.domain_name+'&amount='+gTotalURL+'&currency_code='+this.state.currency_type+'&button_subtype=services&no_note=1&no_shipping=1&rm=1&return='+returnsuccess+'&cancel_return='+returncancel;
                                    document.location.href= linkURL_Paypal;
                                } else {
                                    document.location.href= returnsuccess;
                                }
                            }
                        });
                    // === End - Send to Customer ======
                });
              // === End - Send to Admin ======
            } catch(Error) { 
                localStorage.setItem('type', 'error');
                document.location.href= returnsuccess;
            }
        } else {
            if(this.state.package_type !== "enterprise") {
                var arrfieldName = ['method_payment','is_tnc'];
                var arrvalue = [this.state.method_payment,this.state.is_tnc];
                for(var ck in arrfieldName) {
                    this.validateField(arrfieldName[ck], arrvalue[ck]);
                }     
            } 
        }
    }
    handleSubmit1(e) {
        e.preventDefault();
        if(this.state.package_type !== '' && this.state.domain_name !== '' && this.state.company_name !== ''
            && this.state.requester_name !== '' && this.state.requester_email !== '' && this.state.requester_phone !== ''
            && this.state.country !== '' && this.state.licence_seat !== '' && this.state.month_renewal !== ''
            && this.state.currency_type !== '' 
        ) {
                var domainName,companyName,requesterName,requesterEmail,requesterPhone,country,licenceSeat,enableVault,requestVault,monthRenewal;
                if(this.textInputDomainName.value === '') { domainName = this.state.domain_name; } else { domainName = this.textInputDomainName.value; }
                if(this.textInputCompanyName.value === '') { companyName = this.state.company_name; } else { companyName = this.textInputCompanyName.value; }
                if(this.textInputRequesterName.value === '') { requesterName = this.state.requester_name; } else { requesterName = this.textInputRequesterName.value; }
                if(this.textInputRequesterEmail.value === '') { requesterEmail = this.state.requester_email; } else { requesterEmail = this.textInputRequesterEmail.value; }
                if(this.textInputRequesterPhone.value === '') { requesterPhone = this.state.requester_phone; } else { requesterPhone = this.textInputRequesterPhone.value; }
                if(this.textInputCountry.value === '') { country = this.state.country; } else { country = this.textInputCountry.value; }
                if(this.textInputLicenceSeat.value === '') { licenceSeat = this.state.licence_seat; } else { licenceSeat = this.textInputLicenceSeat.value; }
                if(this.textInputEnableVault.value === '') { enableVault = this.state.enable_vault; } else { enableVault = this.textInputEnableVault.value; }
                if(this.textInputRequestVault.value === '') { requestVault = this.state.request_vault; } else { requestVault = this.textInputRequestVault.value; }
                if(this.textInputMonthRenewal.value === '') { monthRenewal = this.state.month_renewal; } else { monthRenewal = this.textInputMonthRenewal.value; }
                var currencyType = this.state.currency_type;
                var packageType = this.state.package_type;
                var packageTypeName,enablevaultRes;
                var remmonth = 12, proratevaultdef = 50, proratedef = 1000000;

                var datez = new Date(); 
                var monthnow = datez.getMonth() + 1;
                var renYear = datez.getFullYear();
	
                var licmonth = MonthAsNumber(monthRenewal);
                if (licmonth === monthnow) { remmonth = 12; renYear = Number(renYear)+1; }
                else if (licmonth > monthnow) { remmonth = licmonth - monthnow; renYear = Number(renYear); }
                else if (licmonth < monthnow) { remmonth = (licmonth+12)- monthnow; renYear = Number(renYear)+1; }
                
                packageTypeName=packageNameArr[Number(packageType)];
                
                if(this.state.currency_type === "SGD") { proratedef = pricingSGDArr[Number(packageType)]; }
                else { proratedef = pricingUSDArr[Number(packageType)]; }
                
                if(enablevaultRes === "No") { requestVault = 0; }
                if(requestVault < 0) { requestVault = 0; }

                    
                var prorate = RoundDecimal((proratedef/12) * remmonth);
                var proratevault = RoundDecimal((proratevaultdef/12) * remmonth);
                
                var licenseSeatRp = RoundDecimal(licenceSeat * prorate);
                var vaultRp = RoundDecimal(requestVault * proratevault);
            
                var tax = 0;
                if (country === "Singapore") { tax = RoundDecimal((licenseSeatRp + vaultRp)*0.08); } 
                
                var gTotal  = RoundDecimal(licenseSeatRp + vaultRp + tax);
                
                var methdpayment = this.state.method_payment;
                this.setState({
                    package_type: packageType,
                    domain_name: domainName,
                    company_name: companyName,
                    requester_name: requesterName,
                    requester_email: requesterEmail,
                    requester_phone: requesterPhone,
                    country: country,
                    licence_seat: licenceSeat,
                    enable_vault: enableVault,
                    request_vault: requestVault,
                    month_renewal: monthRenewal,
                    year_renewal: renYear,
                    currency_type: currencyType,

                    package_type_name: packageTypeName, 
                    licence_seat_rp: licenseSeatRp,
                    request_vault_rp: vaultRp,
                    tax_value_rp: tax,
                    grand_total: gTotal,
                    method_payment: methdpayment,
                    is_tnc: false,
                    
                    formErrors: {package_type: '',domain_name: '',company_name: '',requester_name: '',requester_email: '',requester_phone: '',country: '',licence_seat: '',enable_vault: '',request_vault: '',month_renewal: '', method_payment: '', is_tnc: '', currencyType: ''},
                    packageTypeValid: false,
                    domainNameValid: false,
                    companyNameValid: false,
                    requesterNameValid: false,
                    requesterEmailValid: false,
                    requesterPhoneValid: false,
                    countryValid: false,
                    licenceSeatValid: false,
                    enableVaultValid: false,
                    requestVaultValid: false,
                    monthRenewalValid: false,
                    methodPaymentValid: false,
                    isTncValid: false,
                    currencyTypeValid: false,
                    formValid: false,
                });

                document.getElementById("first-step").style.display = "none";
                document.getElementById("second-step").style.display = "block";
                if(packageType === "enterprise" || methdpayment === callseller) {
                    document.getElementById("second-step-ent").style.display = "block";
                    document.getElementById("second-step-cost").style.display = "none";
                    document.getElementById("second-step-payment").style.display = "none";
                } else {
                    document.getElementById("second-step-ent").style.display = "none";
                    document.getElementById("second-step-cost").style.display = "block";
                    document.getElementById("second-step-payment").style.display = "block";
                }
                
        } else {
            var arrfieldName = ['package_type','domain_name','company_name','requester_name','requester_email','requester_phone','country','licence_seat','request_vault','month_renewal','currency_type'];
            var arrvalue = [this.state.package_type,this.state.domain_name,this.state.company_name,this.state.requester_name,this.state.requester_email,this.state.requester_phone,this.state.country,this.state.licence_seat,this.state.request_vault,this.state.month_renewal,this.state.currency_type];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }      
        }
    }
    componentDidMount() {
        localStorage.clear();
        document.getElementById("first-step").style.display = "block";
        document.getElementById("second-step").style.display = "none";
        var arrpath = document.location.pathname.split("/");
        var currPath = "";
        if(arrpath.length > 0) { currPath = arrpath[1]; }
        
        if(currPath === "aA56ROwq924msyl") {
            document.getElementById("addlicence-page").style.display = "block";
            document.getElementById("closing-page").style.display = "none";
        } else { 
            var date1 = new Date();
            var date2 = new Date(2023,5,1);
            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            if(parseInt(Difference_In_Days) > 0){
                document.getElementById("addlicence-page").style.display = "block";
                document.getElementById("closing-page").style.display = "none";
            } else {
                document.getElementById("addlicence-page").style.display = "none";
                document.getElementById("closing-page").style.display = "block";
            }
        }
        this.setState({
            package_type: '',
            domain_name: '',
            company_name: '',
            requester_name: '',
            requester_email: '',
            requester_phone: '',
            country: '',
            licence_seat: 0,
            enable_vault: 'No',
            request_vault: 0,
            month_renewal: '',
            year_renewal: '',
            currency_type: '',

            package_type_name: '', 
            licence_seat_rp: 0,
            request_vault_rp: 0,
            tax_value_rp: 0,
            grand_total: 0,
            method_payment: '',
            is_tnc: false,
            formErrors: {package_type: '',domain_name: '',company_name: '',requester_name: '',requester_email: '',requester_phone: '',country: '',licence_seat: '',enable_vault: '',request_vault: '',month_renewal: '', method_payment: '', is_tnc:'', currency_type: ''},
            packageTypeValid: false,
            domainNameValid: false,
            companyNameValid: false,
            requesterNameValid: false,
            requesterEmailValid: false,
            requesterPhoneValid: false,
            countryValid: false,
            licenceSeatValid: false,
            enableVaultValid: false,
            requestVaultValid: false,
            monthRenewalValid: false,
            methodPaymentValid: false,
            isTncValid: false,
            currencyTypeValid: false,
            formValid: false,
        })
    }
    inputcountry() {
        var arrCountry = ["Singapore", "Malaysia", "Indonesia", "United States", "United Kingdom", "Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", 
        "Angola", "Anguilla", "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas", 
        "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
        "Bouvet Island", "Brazil", "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", 
        "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", 
        "Colombia", "Comoros", "Congo", "Congo, The Democratic Republic of The", "Cook Islands", "Costa Rica", "Cote D'ivoire", "Croatia", "Cuba", 
        "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador","Equatorial Guinea",
        "Eritrea", "Estonia", "Ethiopia", "Falkland Islands (Malvinas)", "Faroe Islands", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", 
        "French Southern Territories", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada", "Guadeloupe", 
        "Guam", "Guatemala", "Guinea", "Guinea-bissau", "Guyana", "Haiti", "Heard Island and Mcdonald Islands", "Holy See (Vatican City State)", "Honduras", 
        "Hong Kong", "Hungary", "Iceland", "India", "Iran, Islamic Republic of", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
        "Kazakhstan", "Kenya", "Kiribati", "Korea, Democratic People's Republic of", "Korea, Republic of", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic", 
        "Latvia", "Lebanon", "Lesotho", "Liberia", "Libyan Arab Jamahiriya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao", "Macedonia, The Former Yugoslav Republic of", 
        "Madagascar", "Malawi", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius", "Mayotte", "Mexico", "Micronesia, Federated States of", 
        "Moldova, Republic of", "Monaco", "Mongolia", "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "Netherlands Antilles", 
        "New Caledonia","New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau",
        "Palestinian Territory, Occupied", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", 
        "Reunion", "Romania", "Russian Federation", "Rwanda", "Saint Helena", "Saint Kitts and Nevis", "Saint Lucia", "Saint Pierre and Miquelon", "Saint Vincent and The Grenadines", 
        "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia and Montenegro", "Seychelles", "Sierra Leone", "Slovakia", "Slovenia", 
        "Solomon Islands", "Somalia", "South Africa", "South Georgia and The South Sandwich Islands", "Spain", "Sri Lanka", "Sudan", "Suriname", "Svalbard and Jan Mayen", 
        "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic", "Taiwan, Province of China", "Tajikistan", "Tanzania, United Republic of",  "Thailand", 
        "Timor-leste", "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", 
        "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay", "Uzbekistan", 
        "Vanuatu", "Venezuela", "Vietnam", "Virgin Islands, British", "Virgin Islands, U.S.", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"];
        var arrinputCountry = [];
        if(arrCountry.length > 0) {
          for(var ls=0; ls<arrCountry.length; ls++) {
            arrinputCountry.push(<option value={arrCountry[ls]} key={ls}>{arrCountry[ls]}</option>)
          }
        }
        return arrinputCountry;
    }
    revieworder() {
        var arrResultReview = [];
        var arrContent1 = [this.state.package_type_name+" Licences","Vault Licences","+ 8% GST (Goods & Services Tax)","Total :"];
        var arrContent2 = [this.state.licence_seat,this.state.request_vault,"",""];
        var arrContent3 = [this.state.licence_seat_rp,this.state.request_vault_rp,this.state.tax_value_rp,this.state.grand_total];
        var arrContent4 = ["Yearly","Yearly","",""];
        
        var currencySymbol = "$";
        if(this.state.currency_type === "SGD") { currencySymbol = "S$"; }

        for(var a=0; a<arrContent1.length; a++) {
            var aligncss = "col alignLeft";
            if(a===(arrContent1.length-1)) { aligncss = "col alignRight font-weight-bold"; }
            if(arrContent3[a] > 0) {
                arrResultReview.push(<div key={a} className="row mt-10 padRL-15">
                    <div className={aligncss}>{arrContent1[a]} </div>
                    <div className="col alignCenter" style={{width:"170px"}}>{arrContent2[a]}</div>
                    <div className="col alignCenter" style={{width:"200px"}}>{currencySymbol} {NumberFormats(arrContent3[a])}</div>
                    <div className="col alignCenter" style={{width:"170px"}}>{arrContent4[a]}</div>
                </div>)
            }
        }
        return arrResultReview;
    }
    render() {
        var selCountryList = this.inputcountry();
        var orderReview = this.revieworder();
        return (
           
            <div className="main-container flex-container">
                <div className="w100p divHide" id="closing-page">
                    <div className="divCenter"><div className="mt-50"><img src={logo} alt="" width="160"/></div></div>
                    
                    <div className="mt-20 mb-20">
                        <div style={{height:"20px"}}></div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="divCard divCenter">
                                <div className="mb-20 padRL-15" style={{fontSize:"25pt", color:"#03468a"}}>PAGE NOT AVAILABLE</div>
                                <div className="padRL-15" style={{fontSize:"13pt", color:"#595959"}}>Sorry, this page is not available.</div>
                                <div className="mb-20 padRL-15" style={{fontSize:"13pt", color:"#595959"}}>Please contact your account representative for more information.</div>
                            </div>
                        </div>
                        <div style={{height:"200px"}}></div>
                    </div>
                </div>
                <div className="w100p divHide" id="addlicence-page">
                    <div className="divRight"><div className="mt-20 mr-20"><img src={logo} alt="" width="160"/></div></div>
                    <div className="main-content">
                        <div className="mb-30"><h1>Additional Google Workspace Licence Form</h1></div>
                          
                        {/* ===================== */ }
                        <div id="first-step">
                          <form onSubmit={this.handleSubmit1} id="form1">
                          <div>
                            <div className="row subTitle font-weight-bold">Step 1.1. Choose Licence Type</div>
                            <div className="row group-1">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-6 padRL-10 alignRight">
                                            <p>Choose Available Licence Type :</p>
                                        </div>
                                        <div className="col-6 padRL-10">
                                            <div key="1" className="custom-control custom-radio">
                                                <input type="radio" name="package_type" id="package_type1" 
                                                value="1"
                                                onChange={this.handlePackageType}
                                                className="custom-control-input"
                                                /> 
                                                <label className="ml-10 custom-control-label" htmlFor="package_type1">Google Workspace Business Starter</label>
                                            </div>
                                            <div key="2" className="custom-control custom-radio">
                                                <input type="radio" name="package_type" id="package_type2" 
                                                value="2"
                                                onChange={this.handlePackageType}
                                                className="custom-control-input"
                                                /> 
                                                <label className="ml-10 custom-control-label" htmlFor="package_type2">Google Workspace Business Standard</label>
                                            </div>
                                            <div key="3" className="custom-control custom-radio">
                                                <input type="radio" name="package_type" id="package_type3" 
                                                value="3"
                                                onChange={this.handlePackageType}
                                                className="custom-control-input"
                                                /> 
                                                <label className="ml-10 custom-control-label" htmlFor="package_type3">Google Workspace Business Plus</label>
                                            </div>
                                            <div key="4" className="custom-control custom-radio">
                                                <input type="radio" name="package_type" id="package_type4" 
                                                value="4"
                                                onChange={this.handlePackageType}
                                                className="custom-control-input"
                                                /> 
                                                <label className="ml-10 custom-control-label" htmlFor="package_type4">Google Workspace Enterprise Standard</label>
                                            </div>
                                            <div key="5" className="custom-control custom-radio">
                                                <input type="radio" name="package_type" id="package_type5" 
                                                value="5"
                                                onChange={this.handlePackageType}
                                                className="custom-control-input"
                                                /> 
                                                <label className="ml-10 custom-control-label" htmlFor="package_type5">Google Workspace Enterprise Plus</label>
                                            </div>
                                            <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.package_type}/>  }</div>
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                            <div className="row subTitle font-weight-bold">Step 1.2. Your Company's Domain Name</div>
                            <div>
                                <div className="row font-weight-bold padRL-15 padTop-20">What is your Google Workspace domain name?</div> 
                                    <div className="row padRL-15">Please enter your company's existing Google Workspace domain that you would like to add licences to.</div>
                                    <div className="row group-1">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-6 padRL-10 alignRight"><p>Company's Domain Name :</p></div>
                                            <div className="col-6 padRL-10">
                                                <div><input type="text" className="form-control" name="domain_name"
                                                    placeholder={this.state.domain_name}
                                                    value={this.state.domain_name}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputDomainName = input}/>
                                                </div>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="row subTitle font-weight-bold">Step 1.3. Your Company's Information</div>
                                <div className="row group-1">
                                    <div className="col">
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Company's Name :</p></div>
                                            <div className="col-6 padRL-10"> 
                                                <input type="text" className="form-control" name="company_name"
                                                    placeholder={this.state.company_name}
                                                    value={this.state.company_name}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputCompanyName = input}/>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.company_name}/>  }</div>
                                            </div>        
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Requester's Name :</p></div>
                                            <div className="col-6 padRL-10">
                                                <input type="text" className="form-control" name="requester_name"
                                                    placeholder={this.state.requester_name}
                                                    value={this.state.requester_name}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputRequesterName = input}/>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.requester_name}/>  }</div>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Requester's Email :</p></div>
                                            <div className="col-6 padRL-10"> 
                                                <input type="text" className="form-control" name="requester_email"
                                                    placeholder={this.state.requester_email}
                                                    value={this.state.requester_email}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputRequesterEmail = input}/>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.requester_email}/>  }</div>
                                            
                                            </div>        
                                        </div>
                                        
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Requester's Phone Number :</p></div>
                                            <div className="col-6 padRL-10"> 
                                                <input type="text" className="form-control" name="requester_phone"
                                                    placeholder={this.state.requester_phone}
                                                    value={this.state.requester_phone}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputRequesterPhone = input}/>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.requester_phone}/>  }</div>
                                            
                                            </div>        
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Country of Business :</p></div>
                                            <div className="col-6 padRL-10">
                                                <select name="country" className="form-control" 
                                                    value={this.state.country} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCountry= input}
                                                >
                                                    <option value="" key="">-- please select --</option>
                                                    {selCountryList}
                                                </select>
                                                <div className="mt-05">{ <FormErrors formErrors={this.state.formErrors.country}/>  }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="row subTitle font-weight-bold">Step 1.4. About Your Licence Requirements</div>
                                <div className="row group-1">
                                    <div className="col">
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Accounts / Licences Needed :</p></div>
                                            <div className="col-6 padRL-10"> 
                                                <input type="number" className="form-control" name="licence_seat"
                                                    placeholder={this.state.licence_seat}
                                                    value={this.state.licence_seat}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputLicenceSeat = input}
                                                    min="0"/> 
                                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_seat}/>  }</div>
                                            
                                            </div>        
                                        </div>
                                        <div className="mt-10 divHide" id="vault-area">
                                            <div className="row">
                                                <div className="col-6 padRL-10 alignRight"><p>Enable Vault :</p></div>
                                                <div className="col-6 padRL-10"> 
                                                    <select name="enable_vault" className="form-control" 
                                                        value={this.state.enable_vault} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputEnableVault= input}
                                                    >
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                </div>        
                                            </div>
                                            <div className="row">
                                                <div className="col-6 padRL-10 alignRight"> </div>
                                                <div className="col-6 padRL-10 note"> Applicable only for G Suite Basic </div>
                                            </div>
                                            <div className="row divHide" id="vault-value-area">
                                                <div className="col">
                                                    <div className="row mt-10">
                                                        <div className="col-6 padRL-10 alignRight"><p>Vault Accounts Needed :</p></div>
                                                        <div className="col-6 padRL-10">
                                                            <input type="number" className="form-control" name="request_vault"
                                                                placeholder={this.state.request_vault}
                                                                value={this.state.request_vault}
                                                                onChange={this.handleUserInput}  
                                                                ref={(input) => this.textInputRequestVault = input}
                                                                min="0"/> 
                                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_vault}/>  }</div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row group-1">
                                    <div className="col">
                                        <div className="row font-weight-bold padRL-15 padTop-20">In which month of the year does your Google Workspace licences renew?</div>
                                        <div className="row padRL-15">This should be the same month in which you first signed up for Google Workspace. This is for us to calculate the prorated pricing.</div>
                                        <div className="row padRL-15">If you are unsure which month to select, you can either contact us at <b>+65 6773 0987</b> or email us at &nbsp;<a href="mailto:accountmanagement@point-star.com">accountmanagement@point-star.com</a></div> 
                                        <div className="row group-1">
                                            <div className="col">
                                                <div className="row mt-10">
                                                    <div className="col-6 padRL-10 alignRight"><p>Month of Renewal :</p></div>
                                                    <div className="col-6 padRL-10"> 
                                                        <select name="month_renewal" className="form-control custom-select" 
                                                                value={this.state.month_renewal} 
                                                                onChange={this.handleUserSelect}
                                                                ref={(input) => this.textInputMonthRenewal= input}
                                                            >
                                                            <option value="">-- please select --</option>
                                                            <option value="January">January</option>
                                                            <option value="February">February</option>
                                                            <option value="March">March</option>
                                                            <option value="April">April</option>
                                                            <option value="May">May</option>
                                                            <option value="June">June</option>
                                                            <option value="July">July</option>
                                                            <option value="August">August</option>
                                                            <option value="September">September</option>
                                                            <option value="October">October</option>
                                                            <option value="November">November</option>
                                                            <option value="December">December</option>
                                                        </select>
                                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.month_renewal}/>  }</div>
                                                    
                                                    </div>        
                                                </div>
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row subTitle font-weight-bold">Step 1.5. About Your Payment</div>
                                <div className="row group-1">
                                    <div className="col">
                                        <div className="row mt-10">
                                            <div className="col-6 padRL-10 alignRight"><p>Will be paying using :</p></div>
                                            <div className="col-6 padRL-10"> 
                                                <div key="51" className="custom-control custom-radio">
                                                    <input type="radio" name="currency_type" id="currency_type1" 
                                                    value="USD"
                                                    onChange={this.handleCurrencyType}
                                                    className="custom-control-input"
                                                    /> 
                                                    <label className="ml-10 custom-control-label" htmlFor="currency_type1">USD</label>
                                                </div>
                                                <div key="52" className="custom-control custom-radio">
                                                    <input type="radio" name="currency_type" id="currency_type2" 
                                                    value="SGD"
                                                    onChange={this.handleCurrencyType}
                                                    className="custom-control-input"
                                                    /> 
                                                    <label className="ml-10 custom-control-label" htmlFor="currency_type2">SGD</label>
                                                </div>
                                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.currency_type}/>  }</div>
                                            
                                            </div>        
                                        </div>
                                            
                                    </div>
                                </div>
                            <div className="row group-2 mb-30">
                                <div className="col padRL-10 alignCenter">
                                    <button id="submit-btn" type="submit" className="btn btn-lg btn-blue col" style={{width:"260px"}}><div style={{width:"200px"}}>Proceed to Checkout</div> <div><ShoppingCartIcon className="btn-icon"></ShoppingCartIcon></div></button>
                                </div>
                            </div>
                          </div>
                          </form>
                        </div>
                        {/* ===================== */ }
                        <div id="second-step" className="divHide">
                          <div>
                            <form onSubmit={this.handleSubmit2} id="form2">
                                
                            <div className="row subTitle font-weight-bold">Step 2.1. Review your Order Details</div>
                            <div className="row font-weight-bold padRL-15 padTop-20">Order Details.</div> 
                            <div className="row padRL-15">Here is the information you submitted in the previous step. Please make sure the data you've been submited is correct.</div> 
                            <div className="row group-1 padRL-15">
                                <div className="col mb-30">
                                <div className="row mt-20">
                                    <div className="col-6 padRL-10 alignRight"><p>Selected Licence Type :</p></div>
                                    <div className="col-6 padRL-10"><p>{ this.state.package_type_name }</p></div>
                                </div>
                                <div className="row mt-20">
                                    <div className="col-6 padRL-10 alignRight"><p>Company's Domain Name :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.domain_name}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Company's Name :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.company_name}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Requester's Name :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.requester_name}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Requester's Email :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.requester_email}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Requester's Phone Number :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.requester_phone}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Country of Business :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.country}</p></div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-6 padRL-10 alignRight"><p>No. of Licence(s) Requested :</p></div>
                                    <div className="col-6 padRL-10"><p>{this.state.licence_seat}</p></div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-6 padRL-10 alignRight"><p>Renewal Month :</p></div>
                                    <div className="col-6 padRL-10">{this.state.month_renewal}</div>
                                </div>
                                
                                </div>
                            </div>

                            <div id="second-step-ent" className="row checkout-wrapper-enterprise divHide">
                                <div className="col">
                                    <div className="row mt-30 mb-30">
                                        <div className="col" >Our Team will contact you soon after you Click 'Proceed to Order'.</div>      
                                    </div>
                                </div>
                            </div>


                            <div id="second-step-cost" className="row checkout-wrapper-cost divHide">
                                <div className="col" >
                                    <div className="row  subTitle font-weight-bold">
                                        Step 2.2. Check the Total Costs
                                    </div> 
                                    <div className="row mt-30">
                                        <div className="col" ><b>Total Costs Calculated.</b></div>      
                                    </div>
                                    <div className="mt-20">
                                        <div className="row">
                                            <div className="col" ><b><u>Description</u></b></div>
                                            <div className="col alignCenter" style={{width:"170px"}}><b><u>Quantity</u></b></div>
                                            <div className="col alignCenter" style={{width:"200px"}}><b><u>Price ({this.state.currency_type})</u></b></div>
                                            <div className="col alignCenter" style={{width:"170px"}}><b><u>Frequency</u></b></div>
                                        </div>
                                        <div>{orderReview}</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div id="second-step-payment" className="row checkout-wrapper-paymentmethod mt-30 divHide">
                                <div className="col" >
                                    <div className="row subTitle font-weight-bold">Step 2.3. Method of Payment</div>
                                    <div className="row font-weight-bold padRL-15 padTop-20">Choose Method of Payment :</div> 

                                    <div className="mt-20">
                                        <div className="flex-container">
                                            <div className="col custom-control custom-radio">
                                                <label><input type="radio" name="method_payment" id="method_payment1" 
                                                            value="paypal"
                                                            onChange={this.handleUserSelect}
                                                            className="custom-control-input"
                                                            /> 
                                                    <div className="custom-control-label" htmlFor="method_payment1">
                                                        <div><img src={picpaypal} style={{width: "250px"}} alt="" /></div>
                                                        <div className="mt-10"><b>Credit Card / Paypal</b></div>
                                                        <div><small>Faster Provisioning</small></div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="col custom-control custom-radio">
                                                <label><input type="radio" name="method_payment" id="method_payment3" 
                                                            value="banktransfer"
                                                            onChange={this.handleUserSelect}
                                                            className="custom-control-input"
                                                            /> 
                                                    <div className="custom-control-label" htmlFor="method_payment3">
                                                        <div><img src={picbt} style={{height: "70px"}} alt="" /></div>
                                                        <div className="mt-10"><b>Wire / Bank Transfer</b></div>
                                                        <div><small>Slower Provisioning</small></div>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>  
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.method_payment}/>  }</div>    
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="row padRL-15 mt-30" >
                                <div className="col small padRL-0">
                                    <label><input type="checkbox" name="is_tnc" 
                                        onChange={this.handleUserSelect} />
                                        <span className="ml-10"><i><strong>I agree with the <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank" rel="noreferrer">Terms and Conditions</a> of PointStar Pte Ltd.</strong></i></span>
                                    </label>
                                    <div>{ <FormErrors formErrors={this.state.formErrors.is_tnc}/>  }</div> 
                                    <div className="col small padRL-0"><i>Please ensure that cookies are enabled on your internet browser before proceeding.</i></div>    
                                </div>
                            </div> 
                            <div className="row padRL-15 mt-20 mb-30">
                                <div className="col-6 padRL-10 alignRight">  
                                    <a href="/" className="btn btn-lg btn-blue" style={{color:"#FFF"}}><div style={{width:"80px",marginRight: "10px"}} className="alignLeft">Cancel <HighlightOffIcon className="btn-icon mr-10" style={{marginRight:"10px",marginTop:"2px"}}></HighlightOffIcon></div></a>    
                                </div>
                                <div className="col-6 padRL-10">
                                    <button id="submit-btn-conf" type="submit" className="btn btn-lg btn-blue col" style={{width:"220px"}}><div className="alignLeft">Proceed to Order</div><div><CheckCircleOutlineIcon className="btn-icon mr-10"></CheckCircleOutlineIcon></div></button>
                                </div> 
                            </div> 

                            </form>
                          </div>
                        </div>
                        {/* ===================== */ }

                        
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div>
            </div>
            
        )
    }
}
export default HomePage;